@import 'UI/variables.css';
@import 'UI/general.css';

/* Tailwind css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .companies-card {
    @apply flex cursor-pointer items-center justify-start space-x-7 rounded-md bg-white py-6 px-8 shadow-cabinetCards outline outline-1 outline-transparent transition-outline duration-200 hover:outline-elementHover focus:outline-elementHover;
  }
}

@layer utilities {
  .scroll-blue,
  .scroll-gray {
    /* scrollbar-width: thin; */
  }

  .scroll-blue::-webkit-scrollbar,
  .scroll-gray::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
  }

  .scroll-blue::-webkit-scrollbar {
    background-color: #cdcdcd;
  }

  .scroll-gray::-webkit-scrollbar {
    background-color: #f9fafb;
  }

  .scroll-blue::-webkit-scrollbar-thumb,
  .scroll-gray::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    border-radius: 4px;
  }

  .scroll-blue::-webkit-scrollbar-thumb {
    background-color: #2991dc;
  }

  .scroll-gray::-webkit-scrollbar-thumb {
    background-color: #d1d5db;
  }

  .scroll-docs-templates {
    /* scrollbar-width: thin; */
  }

  .scroll-docs-templates::-webkit-scrollbar {
    width: 2px;
    background-color: #d1d1d1;
    border-radius: 4px;
  }

  .scroll-docs-templates::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #005da1;
  }

  .scroll-md {
    /* scrollbar-width: thin; */
  }

  .scroll-md::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  .scroll-rounded::-webkit-scrollbar {
    border-radius: 4px;
  }

  .scroll-rounded::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }

  .scroll-rounded-xl::-webkit-scrollbar-thumb {
    border-radius: 12px;
  }

  .scroll-blue-default::-webkit-scrollbar {
    background-color: var(--gray-100);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1) inset;
  }

  .scroll-blue-default::-webkit-scrollbar-thumb {
    background-color: var(--gray-default);
  }

  .popup-arrow::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid var(--light-blue-main);
  }

  .transition-max-h {
    transition: max-height 0.4s linear;
  }

  .transition-bg-color {
    transition: background-color 0.1s linear;
  }

  .triange-header {
    width: 17px;
    height: 17px;
    position: absolute;
    top: 6px;
    right: 32px;
    background-color: #fff;
    opacity: 1;
    transform: rotate(45deg);
    box-shadow: 1px 0px 6px rgba(41, 145, 220, 0.3);
    z-index: 1;
  }

  .triange-header::before {
    content: '';
    width: 23px;
    height: 46px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    opacity: 1;
    transform: rotate(45deg) translate(3px, -13px);
    z-index: 23;
  }

  .menu-item-hover:hover {
    background-color: var(--background-main);
  }

  .form-page {
    @apply mx-auto max-w-[600px];
  }

  .border-wrapper {
    @apply rounded-md border border-borderGray py-3 px-4;
  }
}

.online-cash-active-link {
  color: #2991dc;
}

.fader-x::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), currentColor);
}
